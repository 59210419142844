import { useMediaQuery, useTheme } from '@mui/material';
import { EventPayload, track } from '@side/analytics';
import { useCallback } from 'react';
import { getOrCreateClientId } from '../launchdarkly/utils';
import {
  AnalyticsEvent,
  AnalyticsEventNames,
  AnalyticsEventPayloads,
} from './events';

/**
 * Allows us to determine what is the currently active breakpoint,
 * removing the need for passing isMobile, isDesktop, etc. to any
 * of our event trackings
 * @returns Material ui breakpoint
 */
const useAnalyticsBreakpoint = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));
  const isLarge = useMediaQuery(theme.breakpoints.only('lg'));
  const isExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));

  if (isMobile) return 'xs';
  if (isSmall) return 'sm';
  if (isMedium) return 'md';
  if (isLarge) return 'lg';
  if (isExtraLarge) return 'xl';
  return '';
};

/**
 * A helper function to generate a base payload for all analytics events
 *
 * @param breakpoint A string representing the breakpoint of the current screen size
 * @returns
 */
const createDefaultPayload = (
  breakpoint: ReturnType<typeof useAnalyticsBreakpoint>,
) => {
  return {
    breakpoint,
    deviceHeight: window.screen?.availHeight,
    deviceWidth: window.screen?.availWidth,
    location: window?.location?.pathname,
    userAgent: window.navigator.userAgent,
    source: 'identity',
    pseudoid: getOrCreateClientId(),
    referrer: document.referrer,
  };
};

type TrackParameters = Parameters<typeof track>;

export const useTrackEvent = () => {
  const breakpoint = useAnalyticsBreakpoint();

  const trackEvent = useCallback(
    (
      event: AnalyticsEvent,
      additionalPayload?: Record<string, string | number | boolean | null>,
      options?: TrackParameters[2],
    ) => {
      const eventName = AnalyticsEventNames[event];
      const eventPayload = {
        ...createDefaultPayload(breakpoint),
        ...AnalyticsEventPayloads[event],
        ...additionalPayload,
      };
      // Make sure we have a breakpoint before we send off a tracking event.
      // This is due to useAnalyticsBreakpoint() being called and the media query
      // within failing to return a screen size on first page load, but then the media query updates
      // which causes the tracking event to update which causes an additional firing of tracking events
      if (breakpoint) {
        return track(eventName, eventPayload, options);
      }
      // No breakpoint so we return an empty promise
      return () => Promise.resolve();
    },
    [breakpoint],
  );
  return trackEvent;
};

/**
 *
 * React Hook to allow external libraries (i.e. Pantry) to track arbitrary events
 *
 * useTrackEvent should still be used for all events that are defined in AnalyticsEvent
 *
 * @returns trackEvent function that takes in arbitrary eventLabel and eventInfo
 */
export const useTrackExternalEvent = () => {
  const breakpoint = useAnalyticsBreakpoint();

  const trackEvent = useCallback(
    (eventLabel: string, eventInfo: EventPayload & Record<string, unknown>) => {
      const eventPayload = {
        ...createDefaultPayload(breakpoint),
        ...eventInfo,
      };
      // Make sure we have a breakpoint before we send off a tracking event.
      // This is due to useAnalyticsBreakpoint() being called and the media query
      // within failing to return a screen size on first page load, but then the media query updates
      // which causes the tracking event to update which causes an additional firing of tracking events
      if (breakpoint) {
        return track(eventLabel, eventPayload);
      }
      // No breakpoint so we return an empty promise
      return () => Promise.resolve();
    },
    [breakpoint],
  );
  return trackEvent;
};
