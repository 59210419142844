/* eslint-disable no-console */
import { datadogRum } from '@datadog/browser-rum';
import { getRumSessionEnabled } from 'src/utils/datadogRum';
import { sharedInitOptions } from './datadog-config';

export async function initDatadog() {
  // These will log errors if missing env vars and be picked up by Datadog RUM
  if (!process.env.NEXT_PUBLIC_APP_VERSION) {
    console.error('[Side RUM]: Missing APP_VERSION variable');
  }

  if (!process.env.NEXT_PUBLIC_APP_ENV) {
    console.error('[Side RUM]: Missing APP_ENV variable');
  }

  const rumSessionEnabled = await getRumSessionEnabled();
  datadogRum.init({
    ...sharedInitOptions,
    allowedTracingUrls: [
      {
        match: process.env.NEXT_PUBLIC_GRAPH_API || '',
        propagatorTypes: ['datadog', 'tracecontext'],
      },
    ],
    applicationId: '44d4a88b-1fba-468a-83ae-98c9b276b813',
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    // define var conditionalSampleRate as 0 if the userAgent matches a pattern in botPatterns
    // or is a non /identity path otherwise, define conditionalSampleRate as 100
    sessionSampleRate: rumSessionEnabled ? 100 : 0,
    sessionReplaySampleRate: 0, // Not currently using replays
    service: 'identity-ui',
    site: 'datadoghq.com',
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    // Feature flag usage tracking
    // NOTE: Inspector is enabled in src/modules/launchdarkly/provider.tsx
    enableExperimentalFeatures: ['feature_flags'],
  });
}
