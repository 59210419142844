import type { RumInitConfiguration } from '@datadog/browser-rum';
import type { LogsInitConfiguration } from '@datadog/browser-logs';

type SharedDatadogOptions =
  | 'clientToken'
  | 'env'
  | 'sessionSampleRate'
  | 'service'
  | 'site'
  | 'version'
  | 'beforeSend';

export const sharedInitOptions: Pick<
  RumInitConfiguration,
  SharedDatadogOptions
> &
  Pick<LogsInitConfiguration, SharedDatadogOptions> = {
  clientToken: 'pubcf322a836f497faaf55ef76616d06b5a',
  site: 'datadoghq.com', // cspell:disable-line
  env: process.env.NEXT_PUBLIC_APP_ENV,
  version: process.env.NEXT_PUBLIC_APP_VERSION,
  service: 'identity-ui',
};
