import { gql, TypePolicies } from '@apollo/client';

// TODO: remove the extended IdentityLicense externalID
// https://residenetwork.atlassian.net/browse/PLAT-4820
// Had to add this since we need it for the UI but its not in the api yet.
export const localSchema = gql`
  type ClientOnlyQueries {
    identityTeam(id: ID!): IdentityTeam
    identityContact(id: ID!): IdentityContact
    identityUser(id: ID!): IdentityUser
  }
  extend type Query {
    clientOnly: ClientOnlyQueries
  }
  extend type IdentityLicense {
    externalId: String
  }
`;

export const typePolicies: TypePolicies = {
  IdentityTeamMemberRole: {
    // This means that code MUST be queried for this type
    // or Apollo will throw an error and brick the page
    keyFields: ['code'],
  },
  IdentityTeamMember: {
    fields: {
      // IdentityTeamMember.preferences does not have a unique id
      // for Apollo to automatically merge different query results.
      // This causes the cache for this field to be overwritten with the last query result,
      // which may or may not contain all the preferences fields needed for rendering.
      // see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      preferences: {
        merge: true,
      },
    },
  },
  Query: {
    fields: {
      // NOTE: Adding mock data here for teamById was causing caching not to work due to incorrect id being set
      // this is just a namespace for client-only queries.
      // we can remove this if we don't want to namespace
      clientOnly: {
        read() {
          return {
            __typename: 'ClientOnlyQueries',
          };
        },
      },
    },
  },
  // any fields that are not in the graph-api schema
  // we can define here so that they can be queried
  // from the cache directly
  ClientOnlyQueries: {
    fields: {
      // we should only use type policies for cases like this
      // where a field will never exist in graph-api.
      // this is a client-only field used for convenience
      // to query the cache for an individual item by its id
      identityTeam: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'IdentityTeam',
            id: args?.id,
          });
        },
      },
      identityContact: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'IdentityContact',
            id: args?.id,
          });
        },
      },
      identityUser: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'IdentityUser',
            id: args?.id,
          });
        },
      },
    },
  },
};
