import { ReactNode } from 'react';
import { CircularProgress, styled, Box } from '@mui/material';

export const FullSizeFlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
`;

interface Props {
  children?: ReactNode;
}

/**
 *
 * @returns Activity indicator that takes the whole parent size and
 * shows a circular progress icon in the center.
 */
export function LoadingFallback({ children }: Props) {
  return (
    <FullSizeFlexContainer
      flexGrow={1}
      sx={{ gap: 2, justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress role="progressbar" aria-label="Loading" />
      <Box component="p">{children}</Box>
    </FullSizeFlexContainer>
  );
}
