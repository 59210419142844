import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { authLink } from './links/authentication';
import { usePaginatedQuery } from './hooks/use-paginated-query';
import generated from '../../generated/graph-api';
import { localSchema, typePolicies } from './localSchema';

const uri = process.env.NEXT_PUBLIC_GRAPH_API;
const httpLink = createHttpLink({ uri });

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    possibleTypes: generated.possibleTypes,
    typePolicies,
  }),
  // having a local schema allows us to easily
  // query the cache for an individual item
  // by its id, instead of having to traverse
  // the entire graph structure to find it
  typeDefs: localSchema,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export { uri, client, usePaginatedQuery };
